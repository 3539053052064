<template>
  <div class="answer-detail">
    <div class="answer-head">
      <div class="content">
        <div class="question">{{questionInfo.question}}</div>
        <div class="date">
          <span>提问时间：</span>
          <span>{{questionInfo.createTime}}</span>
        </div>
        <div class="rank-tab">
          <span @click="selectTab(1)" class="tab1" :class="{'active':currentTab==1}">最佳</span>
          <span @click="selectTab(2)" class="tab1" :class="{'active':currentTab==2}">最新</span>
          <span @click="answerQuestion(questionInfo.questionId,questionInfo.question,questionInfo.createTime)" class="answer-question">我要解答 ></span>
        </div>
      </div>
    </div>
    <div class="answer-body">
      <div class="content">
        <ul class="question-ul">
          <li class="question-li" v-for="(item, index) in questionList" :key='index'>
            <div class="question-detail" v-html="item.answerEdit"></div>
            <div class="date-operation">
              <span @click="editAnswer(item.answerId,questionInfo.question,questionInfo.createTime,item.answerEdit)" class="edit-answer">编辑</span>
              <div class="operation">
                <img @click="voteAnswer(item.answerId,1,index)" class="fabulous-icon" src="../assets/img/good.svg" alt="">
                <span class="fabulous-span">{{item.goodCount}}</span>
                <img @click="voteAnswer(item.answerId,2,index)" class="step-icon" src="../assets/img/bad.svg" alt="">
                <span class="step-span">{{item.badCount}}</span>
              </div>
              <div class="answer-time">
                <span class="date">回答时间：</span>
                <span class="date">{{item.answerTime}}</span>
              </div>
              
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: '',
    props: {
        msg: String
    },
    data() {
      return {
        currentTab: 1,
        questionList: [],
        questionId: '',
        questionInfo: '',
      }
    },
    methods: {
      selectTab(type) {
        this.currentTab = type
        this.queryAnswerDetails()
      },
      queryAnswerDetails() {
        this.axios.post('/api/question/questionDetail', {
          questionId: this.questionId,
          answerOrderType: this.currentTab
        })
        .then(response=> {
          if(response.data.code == 0){
            this.questionInfo = response.data.data
            this.questionList = response.data.data.answerList
          }else{
            this.$message.error(response.data.message);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      voteAnswer(id, type, index) {
        this.axios.post('/api/answer/comment', {
          answerId: id,
          type: type
        })
        .then(response=> {
          if(response.data.code == 0){
            if(type == 1){
              this.questionList[index]['goodCount']++
            }else{
              this.questionList[index]['badCount']++
            }
          }else{
            this.$message.error(response.data.message);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      answerQuestion(id, question, createTime) {
        this.$router.push({
          path: '/Answer',
          query:{
            questionId: id,
            question: question,
            createTime: createTime,
            isEdit: 0
          }
        })
      },
      editAnswer(id, question, createTime, answer) {
        this.$router.push({
          path: '/Answer',
          query:{
            answerId: id,
            question: question,
            createTime: createTime,
            isEdit: 1,
            answer: answer
          }
        })
      }
    },
    mounted: function() {
      this.questionId = this.$route.query.questionId 
      this.queryAnswerDetails()
    }
}
</script>

<style scoped lang="less"> 
  .answer-detail{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: rgba(240, 242, 243, 1);
    .content{
      width: 1200px;
      margin: 0 auto;
    }
    .answer-head{
      background: #fff;
      height: 138px;
      padding: 28px 0 0 0;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
      z-index: 10000;
      .question{
        color: rgba(0, 0, 0, 1);
        font-size: 18px;
      }
      .date{
        color: rgba(121, 136, 155, 0.7);
        font-size: 13px;
        margin-top: 8px;
      }
      .rank-tab{
        margin: 10px 0 23px 0;
        .tab1{
          display: inline-block;
          width: 64px;
          height: 28px;
          text-align: center;
          line-height: 28px;
          margin-right: 8px;
          font-size: 13px;
          cursor: pointer;
          color: rgba(121, 136, 155, 1);
          background: rgba(121, 136, 155, 0.1);
        }
        span.active{
          color: rgba(24, 144, 255, 1);
          background: rgba(24, 144, 255, 0.1);
        }
        .answer-question{
          float: right;
          color: rgba(0, 82, 217, 1);
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
    .answer-body{
      flex: 1;
      overflow-y: auto;
      padding-bottom: 24px;
      .question-ul{
        margin: 0px;
        padding: 0px;
        .question-li{
          padding: 24px 24px 0 24px;
          margin-top: 16px;
          background: #fff;
          border-radius: 4px;
          .question-detail{
            padding-bottom: 24px;
            border-bottom: 1px solid rgba(229, 229, 229, 1);
            color: rgba(61, 72, 93, 1);
            font-size: 14px;
          }
          .date-operation{
            height: 52px;
            padding: 16px 0 0 0;
            .edit-answer{
              color: rgba(0, 82, 217, 1);
              font-size: 13px;
              cursor: pointer;;
            }
            .answer-time{
              float: right;
              margin-right: 49px;
            }
            .date{
              color: rgba(121, 136, 155, 0.7);
              font-size: 13px;
            }
            .operation{
              float: right;
              .fabulous-icon{
                color: rgba(121, 136, 155, 0.7);
                font-size: 16px;
                cursor: pointer;
                width: 16px;
              }
              .fabulous-span{
                color: rgba(121, 136, 155, 0.7);
                font-size: 13px;
                margin: 0 32px 0 8px;
              }
              .step-icon{
                color: rgba(121, 136, 155, 0.7);
                font-size: 16px;
                cursor: pointer;
                width: 16px;
              }
              .step-span{
                color: rgba(121, 136, 155, 0.7);
                font-size: 13px;
                margin: 0 0 0 8px;
              }
            }
          }
        }
      }
    }
  }
</style>
