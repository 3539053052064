<template>
  <div class="index">
    <div class="search-box">
      <div class="title">有问题，问小文</div>
      <div class="search-input">
        <a-input-search
          placeholder="输入您的问题"
          enter-button="搜索"
          size="large"
          @search="onSearch"
        />
      </div>
      <div @click="goRank" class="wait-answer">还有{{newQuestionCount}}个问题待解答 ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      question: '',
      newQuestionCount: 0
    }
  },
  methods: {
    onSearch(value) {
      if(value.length != 0){
        this.$router.push({
          path: '/Result',
          query:{
            question: value
          }
        })
      }
    },
    goRank() {
      this.$router.push("/NewQuestion")
    },
    queryNumData() {
      this.axios(
        {
          method:'get',
          url:'/api/question/newQuestionCount', 
        }
      )
      .then(response=> {
        if(response.data.code == 0){
          this.newQuestionCount = response.data.data.newQuestionCount
        }else{
          this.$message.error(response.data.userTip);
        }
      })
      .catch(error=> {
        console.log(error)
      })
    }
  },
  mounted: function() {
    this.queryNumData()
  }
}
</script>

<style scoped lang="less"> 
  .index{
    width: 100%;
    height: 100%;
    position: relative;
    .search-box{
      display: inline-block;
      width: 640px;
      height: 165px;
      position: absolute;
      left: 50%;
      margin-left: -320px;
      top: 35%;
      margin-top: -80px;
      .title{
        color: rgba(0, 0, 0, 1);
        font-size: 32px;
        font-weight: bold;
        text-align: center;
      }
      .search-input{
        margin-top: 24px;
      }
      .wait-answer{
        margin-top: 24px;
        color: rgba(0, 82, 217, 1);
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
</style>
