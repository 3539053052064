<template>
  <div class="result">
    <div class="search-input">
      <a-input-search
        placeholder="输入您的问题"
        enter-button="搜索"
        size="large"
        v-model.trim="question"
        @search="onSearch"
      />
    </div>
    <div class="result-content">
      <div class="result-detail">
        <div class="item-list" v-for="(item,index) in infoData" :key='index'>
          <div @click="viewDetails(item.questionId)" class="item-title">{{item.question}}</div>
          <div v-if="item.hasAnswer" class="item-content">{{item.answer}}</div>
          <div class="no-answer" v-if="item.hasAnswer==false">该问题没有答案</div>
          <div style="color:rgba(121, 136, 155, 0.7);font-size:13px;margin-top:4px;">{{item.createTime}}</div>
          <div v-if="item.hasAnswer==false" class="go-answer">
            <span @click="answerQuestion(item.questionId,item.question,item.createTime)">去解答 ></span>
          </div>
        </div>
      </div>
      <div class="rank-list">
        <div @click="goRank" class="rank-title">排行榜 ></div>
        <div class="rank-tab">
          <span @click="selectTab(1)" :class="{'active':currentTab==1}">最热</span>
          <span @click="selectTab(2)" :class="{'active':currentTab==2}">最新</span>
        </div>
        <ul class="rank-ul">
          <li @click="viewDetails(item.id)" class="rank-li" v-for="(item,index) in rankList" :key='index'>
            <span class="order" :class="{'first':index==0,'second':index==1,'third':index==2,}">{{index+1}}</span>
            <span class="title">{{item.question}}</span>
            <div class="degree">
              <img class="fire" src="../assets/img/fire.svg" alt="">
              <span class="num">{{item.visitCount}}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: '',
    props: {
      msg: String
    },
    data() {
      return {
        infoData: [],
        rankList: [],
        currentTab: 1,
        question: ''
      }
    },
    methods: {
      onSearch() {
        this.queryAnswerData()
      },
      selectTab(type) {
        this.currentTab = type
        this.queryRankList()
      },
      goRank() {
        this.$router.push("/RankingList")
      },
      viewDetails(id) {
        let text= this.$router.resolve({
          path: '/AnswerDetails',
          query:{
            questionId: id,
          }
        });  
        window.open(text.href, '_blank')
      },
      queryAnswerData() {
        this.axios.post('/api/question/searchResultList', {
          question: this.question
        })
        .then(response=> {
          if(response.data.code == 0){
            this.infoData = response.data.data
          }else{
            this.$message.error(response.data.message);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      queryRankList() {
        this.axios(
          {
            method:'get',
            url:'/api/question/rankingList', 
            params: {
              size: 15,
              current: 1,
              orderType: this.currentTab
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            console.log(response.data.data.records)
            this.rankList = response.data.data.records
          }else{
            this.$message.error(response.data.userTip);
          }
        })
        .catch(error=> {
            console.log(error)
        })
      },
      answerQuestion(id, question, createTime) {
        this.$router.push({
          path: '/Answer',
          query:{
            questionId: id,
            question: question,
            createTime: createTime,
            isEdit: 0
          }
        })
      },
    },
    mounted: function() {
      this.question = this.$route.query.question
      this.queryAnswerData()
      this.queryRankList()
    }
  }
</script>

<style scoped lang="less"> 
  .result{
    width: 100%;
    height: 100%;
    padding: 28px 168px 28px 160px;
    .search-input{
      width: 960px;
    }
    .result-content{
      display: flex;
      padding-top: 32px;
      .result-detail{
        flex: 1;
        padding-right: 240px;
        .item-list{
          margin-bottom: 32px;
        }
        .item-title{
          color: rgba(0, 82, 217, 1);
          font-size: 17px;
          text-decoration: underline;
          margin-bottom: 8px;
          cursor: pointer;
        }
        .item-content{
          color: rgba(18, 18, 18, 1);
          font-size: 13px;
          word-break:break-all;
          // margin-bottom: 32px;
        }
        .no-answer{
          color: rgba(18, 18, 18, 1);
          font-size: 13px;
          margin-top: 8px;
        }
        .go-answer{
          margin-top: 7px;
          span{
            color: rgba(37, 111, 231, 1);
            font-size: 13px;
            cursor: pointer;
          }
        }
      }
      .rank-list{
        width: 440px;
        .rank-title{
          color: rgba(0, 82, 217, 1);
          font-size: 14px;
          cursor: pointer;
        }
        .rank-tab{
          margin: 10px 0 23px 0;
          span{
            display: inline-block;
            width: 64px;
            height: 28px;
            text-align: center;
            line-height: 28px;
            margin-right: 8px;
            font-size: 13px;
            cursor: pointer;
            color: rgba(121, 136, 155, 1);
            background: rgba(121, 136, 155, 0.1);
          }
          span.active{
            color: rgba(24, 144, 255, 1);
            background: rgba(24, 144, 255, 0.1);
          }
        }
        .rank-ul{
          padding-left: 0px;
          .rank-li{
            margin-bottom: 12px;
            cursor: pointer;
            .order{
              display: inline-block;
              width: 16px;
              float: left;
              color: rgba(121, 136, 155, 1);
              font-size: 14px;
              font-weight: bold;
              height: 16px;
              text-align: center;
              margin-right: 8px;
              line-height: 16px;
              margin-top: 4px;
            }
            .first{
              background: rgba(212, 48, 48, 1);
              color: #fff;
            }
            .second{
              background: rgba(255, 141, 26, 1);
              color: #fff;
            }
            .third{
              background: rgba(255, 195, 0, 1);
              color: #fff;
            }
            .title{
              display: inline-block;
              width: 348px;
              padding-right: 24px;
              color: rgba(18, 18, 18, 1);
              font-size: 14px;
            }
            .degree{
              display: inline-block;
              float: right;
              .fire{
                display: inline-block;
                width: 16px;
                margin-right: 2px;
              }
              .num{
                font-size: 12px;
                color: rgba(121, 136, 155, 1);
                margin-top: 4px;
                float: right;
              }
            }
          }
        }
      }
    } 
  }
</style>
