import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '../views/Index.vue';
import Result from '../views/Result.vue';
import RankingList from '../views/RankingList.vue';
import NewQuestion from '../views/NewQuestion.vue';
import AnswerDetails from '../views/AnswerDetails.vue';
import Answer from '../views/Answer.vue';

Vue.use(VueRouter)
const routes = [
  { 
    path: '/', 
    redirect: '/Index' 
  },
  { 
    path: '/Index', 
    component: Index 
  },
  { 
    path: '/Result', 
    component: Result 
  },
  { 
    path: '/RankingList', 
    component: RankingList 
  },
  { 
    path: '/NewQuestion', 
    component: NewQuestion 
  },
  { 
    path: '/AnswerDetails', 
    component: AnswerDetails 
  },
  { 
    path: '/Answer', 
    component: Answer 
  },
]
const router = new VueRouter({
  routes
})
export default router;
