<template>
  <div id="app" style="width:100%;height:100%;">
    <div class="index-page">
      <router-link to="/Index">
        <img class="logo" src="./assets/img/logo.png" alt="">
        <span>首页</span>
      </router-link>
    </div>
    <a-config-provider :locale="locale">
      <router-view class="body-content"></router-view>
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  name: 'App',
  components: {

  },
  data () {
    return {
      locale: zhCN
    }
  }
}
</script>

<style scoped lang="less">
  @import "./assets/css/global.css";
  #app{
    height: 100%;
    width: 100%;
    padding-top: 52px;
    .index-page{
      height: 52px;
      line-height: 52px;
      padding-left: 32px;
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08);
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      background: #fff;
      z-index: 10;
      .logo{
        width: 24px;
        float: left;
        margin: 14px 4px 0 0;
      }
      span{
        float: left;
        color: rgba(51, 51, 51, 1);
        font-size: 14px;
      }
    }
  }
</style>
