<template>
  <div class="ranking-list">
    <div class="rank-tab">
      <span @click="selectTab(1)" :class="{'active':currentTab==1}">最热</span>
      <span @click="selectTab(2)" :class="{'active':currentTab==2}">最新</span>
    </div>
    <ul class="rank-ul">
      <li class="rank-li" v-for="(item,index) in rankList" :key='index'>
        <div class="rank-order">
          <span class="order" :class="{'first':index==0,'second':index==1,'third':index==2,}">{{index+1}}</span>
          <img class="fire" src="../assets/img/fire.svg" alt="">
          <span class="num">{{item.visitCount}}</span>
        </div>
        <div class="rank-body">
          <div @click="viewDetails(item.id)" class="rank-title">{{item.question}}</div>
          <div class="rank-content">{{item.answer}}</div>
          <div style="color:rgba(121, 136, 155, 0.7);font-size:13px;margin-top:4px;">{{item.createTime}}</div>
        </div>
      </li>
    </ul>
    <div class="view-more" v-if="ifShowMore">
      <span @click="viewMore">点击加载更多~</span>
    </div>
  </div>
</template>

<script>
export default {
    name: '',
    props: {
      msg: String
    },
    data() {
      return {
        rankList: [],
        ifShowMore: true,
        ifInitialization: false,
        currentTab: 1,
        currentPage: 1
      }
    },
    methods: {
      selectTab(type) {
        this.currentPage = 1
        this.currentTab = type
        this.ifInitialization = false
        this.queryRankList()
      },
      queryRankList() {
        this.axios(
          {
            method:'get',
            url:'/api/question/rankingList', 
            params: {
              size: 15,
              current: this.currentPage,
              orderType: this.currentTab
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            if(this.ifInitialization){
              let data = response.data.data.records
              this.rankList = this.rankList.concat(data)
            }else{
              this.rankList = response.data.data.records
            }
            let total = response.data.data.total
            let current = response.data.data.current
            if(total == (current-1)*15 + response.data.data.records.length){
              this.ifShowMore = false
            }else{
              this.ifShowMore = true
            }
          }else{
            this.$message.error(response.data.userTip);
          }
        })
        .catch(error=> {
            console.log(error)
        })
      },
      viewMore() {
        this.currentPage++
        this.ifInitialization = true
        this.queryRankList()
      },
      viewDetails(id) {
        let text= this.$router.resolve({
          path: '/AnswerDetails',
          query:{
            questionId: id,
          }
        });  
        window.open(text.href, '_blank')
      },
    },
    mounted: function() {
      this.queryRankList()
    }
}
</script>

<style scoped lang="less"> 
  .ranking-list{
    width: 100%;
    height: 100%;
    padding: 0 250px 0 160px;
    .rank-tab{
      padding-top: 28px;
      span{
        display: inline-block;
        width: 64px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        margin-right: 8px;
        font-size: 13px;
        cursor: pointer;
        color: rgba(121, 136, 155, 1);
        background: rgba(121, 136, 155, 0.1);
      }
      span.active{
        color: rgba(24, 144, 255, 1);
        background: rgba(24, 144, 255, 0.1);
      }
    }
    .rank-ul{
      padding: 0px;
      padding-bottom: 24px;
      .rank-li{
        margin-top: 28px;
        display: flex;
        .rank-order{
          width: 92px;
          padding-right: 24px;
          display: inline-block;
          .order{
            display: inline-block;
            color: rgba(121, 136, 155, 1);
            font-size: 14px;
            font-weight: bold;
            float: left;
            height: 16px;
            width: 16px;
            text-align: center;
            line-height: 16px;
            margin-top: 2px;
          }
          .first{
            background: rgba(212, 48, 48, 1);
            color: #fff;
          }
          .second{
            background: rgba(255, 141, 26, 1);
            color: #fff;
          }
          .third{
            background: rgba(255, 195, 0, 1);
            color: #fff;
          }
          .fire{
            display: inline-block;
            width: 16px;
            margin-right: 2px;
            margin-left: 8px;
            margin-top: 2px;
            float: left;
          }
          .num{
            font-size: 12px;
            color: rgba(121, 136, 155, 1);
            float: left;
            margin-top: 2px;
          }
        }
        .rank-body{
          flex: 1;
          .rank-title{
            color: rgba(0, 82, 217, 1);
            font-size: 17px;
            text-decoration: underline;
            cursor: pointer;
          }
          .rank-content{
            color: rgba(18, 18, 18, 1);
            font-size: 13px;
            margin-top: 8px;
          }
        }
      }
    }
    .view-more{
      text-align: center;
      padding-bottom: 24px;
      span{
        cursor: pointer;
      }
    }
  }

</style>
