<template>
  <div class="answer-detail">
    <div class="answer-head">
      <div class="content">
        <div class="question">{{question}}</div>
        <div class="date">
          <span>提问时间：</span>
          <span>{{createTime}}</span>
        </div>
      </div>
    </div>
    <div class="answer-body">
      <div class="content">
        <div id="editor"></div>
      </div>
      <div class="btn-group">
        <a-button @click="offSave" type="primary" ghost>
        取消
      </a-button>
      <a-button @click="saveContent" type="primary">
        保存
      </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import E from "wangeditor";
export default {
    name: '',
    props: {
        msg: String
    },
    data() {
      return {
        questionId: '',
        question: '',
        createTime: '',
        editor: null,
        isEdit: null,
        answerId: '',
        answer: ''
      }
    },
    methods: {
      initData() {
        var ele = document.getElementById('editor');
        this.editor = new E(ele);
        this.editor.create();


        if(this.isEdit == 1){
          this.editor.txt.html(this.answer)
        }
      },
      saveContent() {
        let answer = this.editor.txt.text()
        let answerEdit = this.editor.txt.html()
        this.axios.post('/api/answer', {
          questionId: this.isEdit==1?'':this.questionId,
          answerId: this.isEdit==1?this.answerId:'',
          answer: answer,
          answerEdit: answerEdit,
        })
        .then(response=> {
          if(response.data.code == 0){
            this.$message.success('回答成功')
            setTimeout(()=>{
              this.$router.go(-1)
            },500)
          }else{
            this.$message.error(response.data.message);
          }
        })
        .catch(error=> {
          console.log(error)
        })
      },
      offSave() {
        this.$router.go(-1)
      }
    },
    mounted: function() {
      this.questionId = this.$route.query.questionId 
      this.question = this.$route.query.question 
      this.createTime = this.$route.query.createTime
      this.isEdit = this.$route.query.isEdit
      this.answerId = this.$route.query.answerId
      this.answer = this.$route.query.answer
      this.initData()
    }
}
</script>

<style scoped lang="less"> 
  .answer-detail{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: rgba(240, 242, 243, 1);
    .content{
      width: 1200px;
      margin: 0 auto;
    }
    .answer-head{
      background: #fff;
      height: 106px;
      padding: 28px 0 0 0;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
      .question{
        color: rgba(0, 0, 0, 1);
        font-size: 18px;
      }
      .date{
        color: rgba(121, 136, 155, 0.7);
        font-size: 13px;
        margin-top: 8px;
      }
      .rank-tab{
        margin: 10px 0 23px 0;
        span{
          display: inline-block;
          width: 64px;
          height: 28px;
          text-align: center;
          line-height: 28px;
          margin-right: 8px;
          font-size: 13px;
          cursor: pointer;
          color: rgba(121, 136, 155, 1);
          background: rgba(121, 136, 155, 0.1);
        }
        span.active{
          color: rgba(24, 144, 255, 1);
          background: rgba(24, 144, 255, 0.1);
        }
      }
    }
    .answer-body{
      padding-top: 24px;
      .btn-group{
        margin-top: 18px;
        height: 52px;
        padding: 10px 0 0 0;
        text-align: center;
        button{
          margin-right: 12px;
        }
      }
    }
  }
</style>
