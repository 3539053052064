<template>
  <div class="new-question">
    <div>
      <div class="search-input">
        <a-input-search
          placeholder="输入您的问题"
          enter-button="搜索"
          size="default"
          v-model.trim="question"
          @search="onSearch"
        />
      </div>
       <span class="search-result">共计{{total}}个问题</span>
    </div>
    <div style="clear:both"></div>
    <div class="rank-tab">
      <span @click="selectTab(1)" :class="{'active':currentTab==1}">最热</span>
      <span @click="selectTab(2)" :class="{'active':currentTab==2}">最新</span>
    </div>
    <ul class="question-ul">
      <li class="question-li" v-for="(item,index) in questionList" :key='index'>
        <div class="title">{{item.question}}</div>
        <div class="date">
          <span>提出时间：</span>
          <span>{{item.createTime}}</span>
        </div>
        <div @click="goAnswer(item.id, item.question, item.createTime)" class="answer">去解答 ></div>
      </li>
    </ul>
    <div class="view-more" v-if="ifShowMore">
      <span @click="viewMore">点击加载更多~</span>
    </div>
  </div>
</template>

<script>
export default {
    name: '',
    props: {
        msg: String
    },
    data() {
      return {
        currentTab: 1,
        questionList: [],
        currentPage: 1,
        ifShowMore: false,
        ifInitialization: false,
        question: '',
        total: 0
      }
    },
    methods: {
      onSearch() {
        this.queryQuestionData()
      },
      selectTab(type) {
        this.currentPage = 1
        this.currentTab = type
        this.ifInitialization = false
        this.queryQuestionData()
      },
      goAnswer(id, question, createTime) {
        this.$router.push({
          path: '/Answer',
          query:{
            questionId: id,
            question: question,
            createTime: createTime,
            isEdit: 0
          }
        })
      },
      queryQuestionData() {
        this.axios( 
          {
            method:'get',
            url:'/api/question/newQuestionList', 
            params: {
              size: 15,
              current: this.currentPage,
              orderType: this.currentTab,
              question: this.question
            }
          }
        )
        .then(response=> {
          if(response.data.code == 0){
            if(this.ifInitialization){
              let data = response.data.data.records
              this.questionList = this.questionList.concat(data)
            }else{
              this.questionList = response.data.data.records
            }
            let total = response.data.data.total
            let current = response.data.data.current
            if(total == (current-1)*15 + response.data.data.records.length){
              this.ifShowMore = false
            }else{
              this.ifShowMore = true
            }
            this.total = response.data.data.total
          }else{
              this.$message.error(response.data.message);
          }
        })
        .catch(error=> {
            console.log(error)
        })
      },
      viewMore() {
        this.currentPage++
        this.ifInitialization = true
        this.queryQuestionData()
      }
    },
    mounted: function() {
      this.queryQuestionData()
    }
}
</script>

<style scoped lang="less"> 
  .new-question{
    padding: 28px 0 0 160px;
    .search-input{
      width: 560px;
      display: inline-block;
      float: left;
    }
    .search-result{
      padding: 0 0 0 24px;
      margin-top: 6px;
      float: left;
      color: rgba(18, 18, 18, 1);
    }
    .rank-tab{
      margin-top: 28px;
      span{
        display: inline-block;
        width: 64px;
        height: 28px;
        text-align: center;
        line-height: 28px;
        margin-right: 8px;
        font-size: 13px;
        cursor: pointer;
        color: rgba(121, 136, 155, 1);
        background: rgba(121, 136, 155, 0.1);
      }
      span.active{
        color: rgba(24, 144, 255, 1);
        background: rgba(24, 144, 255, 0.1);
      }
    }
    .question-ul{
      padding: 0px;
      padding-bottom: 1px;
      margin: 32px 0 0 0;
      .question-li{
        margin-bottom: 32px;
        .title{
          color: rgba(0, 0, 0, 1);
          font-size: 17px;
          // text-decoration: underline;
        }
        .date{
          color: rgba(121, 136, 155, 1);
          font-size: 13px;
          margin: 8px 0;
        }
        .answer{
          color: rgba(0, 82, 217, 1);
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
    .view-more{
      text-align: center;
      padding-bottom: 24px;
      span{
        cursor: pointer;
      }
    }
  }
</style>
